import React from "react";
import { Link } from "react-router-dom";
import "./ArticleTwentyThree.scss";
import NewsPhotoTwentyThree from "../../../assets/images/Photos/NewsPhotoTwentyThree.jpg";

import Footer from "../../../components/Footer/Footer";

const ArticleTwentyThree = () => {
  return (
    <>
      <div className="article-page">
        <span className="article-page__date">August 20, 2024</span>
        <h3 className="article-page__title">
          National Traffic Awareness Month at RSG International
        </h3>
        <p className="article-page__text">
          <br></br>
          During National Traffic Awareness Month, we remind everyone about the importance of road safety and the collective responsibility we all share in reducing traffic-related incidents.
          <br></br>
          <br></br>
          With global traffic fatalities still being the leading cause of death, particularly among young people, raising awareness to this issue is critical. According to the World Health Organization (WHO), approximately 1.3 million people die each year as a result of car crashes, and between 20 to 50 million more suffer non-fatal injuries, often leading to long-term disabilities. In the United States, the National Highway Traffic Safety Administration (NHTSA) reported a significant rise in traffic fatalities, with 42,939 deaths in 2021, marking a 10.5% increase from the previous year, and preliminary data for 2022 and 2023 suggests that this trend continues. These alarming statistics underscore the need for greater public awareness and proactive measures to improve road safety.
          <br></br>
          <br></br>
          As road safety specialists, we’re dedicated to raising awareness and doing our best to enhance road safety for all.
          <br></br>
          <br></br>
        </p>
        <img
          className="article-page__image"
          src={NewsPhotoTwentyThree}
          alt="Road Safety Crew setting up a safe workplace on a highway ramp."
        />

        <div className="last-navigation">
          {/* Adjust these links as needed */}
          <Link
            to="/project-highlight-bk-enterprises-installing-concrete-barrier-in-bucks-county-pennsylvania" // Update the link to the previous article
            className="nav-button"
          >
            Previous
          </Link>
          {/* Add next button if there is a following article */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleTwentyThree;
